import React, { createContext, useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [choosenCandidate, setChoosenCandidate] = useState("");
    const [candidateList, setCandidateList] = useState([]);
    const [candidateId, setCandidateId] = useState("");
    let inactivityTimeout;
    let warningShown = false;
    // let hasWarningShown = false; // Flag to track if warning has been shown

    const login = (userData) => {
        setUser(userData);
        // console.log("session Id" + logSessionId);
        setIsLoggedIn(true);
        localStorage.setItem('sessionId', JSON.stringify(userData.global_session_id));
        localStorage.setItem('user', JSON.stringify(userData));
        resetWarningState();
    // resetTimer();
    };

    const setCandidate = (email) => {
        setChoosenCandidate(email);
        if(email){
            localStorage.setItem('Candidate',JSON.stringify(email))
        }
        else{
            localStorage.removeItem('Candidate')
        }
    }


    const resetTimer = () => {
        // If warning is already shown, don't do anything
        if (warningShown) {
            return;
        }
    
        // Clear existing timeout
        clearTimeout(inactivityTimeout);
    
        // Set new timeout for 14 minutes
        inactivityTimeout = setTimeout(() => {
            // Show warning only if it hasn't been shown yet
            if (!warningShown) {
                warningShown = true;
                logout();
                
                toast.error('Session has been closed. Please log in again', {
                    draggable: "true",
                    autoClose: false,
                    className: "toast-center",
                });
    
                // Set logout timeout for 1 minute after warning
                setTimeout(() => {
                    // logout();
                }, 14 * 60 * 1000); // 1 minute
            }
        }, 15 * 60 * 1000);
    };

    // Reset everything when user logs in
const resetWarningState = () => {
    warningShown = false;
    clearTimeout(inactivityTimeout);
};

// Add event listeners for user activity
document.addEventListener('mousemove', resetTimer);
document.addEventListener('keypress', resetTimer);
document.addEventListener('click', resetTimer);

// Initial timer start
resetTimer();

    const logout = () => {
        // Clear any session or user data
        setUser(null); // or your method of clearing user state
        localStorage.removeItem('authToken'); // if you're using localStorage to manage tokens
        localStorage.removeItem('sessionId'); // if you're using localStorage to manage tokens
        localStorage.removeItem('user'); // if you're using localStorage to manage tokens
        setIsLoggedIn(false);
        console.log("logged you out");
    };
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storeEmail = localStorage.getItem('Candidate')
        if (storedUser) {
            setUser(JSON.parse(storedUser));
            setIsLoggedIn(true);
        }
        if(storeEmail){
            setChoosenCandidate(JSON.parse(storeEmail));
        }

    }, []);

    // resetTimer();

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, user, logout,  choosenCandidate, setChoosenCandidate, setCandidate, candidateList, setCandidateList, candidateId, setCandidateId }}>
            {children}
        </AuthContext.Provider>
    );
};





export const useAuth = () => {
    return useContext(AuthContext);
};
