import React, { useState, useEffect, Component } from 'react';
import Dashnav from '../../components/dashnav';
import "./JobSearch.css";
import JobSearchIcon from "./jobSearch.svg";
import Profile from "../../images/your-profile.svg";
import ActiveJobs from './ExistingJobComponents/existingjobComponents';

const ExistingJobSearch = () => {
    return (
        <div>
            <div className='main-dash row-flex'>
                <Dashnav />
                <section className="w-100">
                    <div className="top-section-jobSearch row-flex">
                        <img src={JobSearchIcon} alt="" />
                        <p className="job-heading">Existing Job Search</p>
                        <button className="name-profile">
                            <img src={Profile} alt="" className='profile' />
                            Your Profile</button>
                    </div>
                    <ActiveJobs/>
                </section>
            </div>
        </div>
    )
}

export default ExistingJobSearch    