import React, { useState, useEffect, Component } from 'react';
import { useNavigate } from "react-router-dom";
import Dashnav from '../../components/dashnav';
import { useAuth } from '../../authContext';
import "./style.css";
import ProfileDef from "../dashboard/profileIcon.svg";
import BenchIcon from "./benchIcon.svg";
import newCandidateIcon from "../dashboard/RegisterIcon.svg";
import ViewIcon from "./view-icon.svg";
import EditIcon from "./edit-icon.svg";
import CandidateDetailsModal from './CandidateDetailsModal'; // Your new modal component
import CandidateModal from "./candidateModal";
import numberOfCandidateIcon from "../dashboard/images.png";
import CandidateDetailsEdit from './CandidateDetailsEdit';
import "./sidebarStyle.css";
import TopUser from "../../images/top-user.svg";
import Profile from "../../images/your-profile.svg";
import AddIcon from "../../images/add-btn-icon.svg";
import BenchDetails from "./BenchCandidateDetails";
import { ReactComponent as NameUserIcon } from "../../images/name-user-icon.svg";
import { ReactComponent as CandidateNameIcon } from "../../images/candidate-name.svg";
import { ReactComponent as EmailIcon } from "../../images/email.svg";
import { ReactComponent as ActionIcon } from "../../images/actions-icon.svg";
import { ReactComponent as CompanyIcon } from "../../images/company.svg";
import { ReactComponent as VisaIcon } from "../../images/visa-icon.svg";
import { ReactComponent as StatusIcon } from "../../images/status.svg";
import { ReactComponent as DeleteIcon } from "../../images/icon-delete.svg";
import { ReactComponent as EditIconButton } from "../../images/icon-edit.svg";
import { ReactComponent as RightTickIcon } from "../../images/icon-right-tick.svg";
import { ReactComponent as Female } from "../../images/female.svg";
import { ReactComponent as Others } from "../../images/otherGender.svg";
import { useGlobalContext } from '../../globalContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BenchCandidates = () => {
    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const {searchBTerm, setBSearchTerm} = useGlobalContext('');
    const {visaBStatusFilter, setBVisaStatusFilter} = useGlobalContext('');
    const {recruiterBFilter, setBRecruiterFilter} = useGlobalContext('');
    const [locationFilter, setLocationFilter] = useState('');
    const [candidateCount, setCandidateCount] = useState('');
    const [recruitersBList, setBRecruitersList] = useState([]); // State for recruiters list
    const {teamsBFilter, setBTeamsFilter} = useGlobalContext('');
    const {currentStatusBench, setCurrentStatusBench} = useGlobalContext("");
    const [dataDelete, setDataDelete] = useState(false);
    const navigate = useNavigate();
    const [openDetails, setOPenDetails] = useState(false)
    const [onBoardedCompany, setOnBoardedCompany] = useState('');



    useEffect(() => {
        setCandidates([]);
        let storedUser = JSON.parse(localStorage.getItem('user'));
        console.log("storedUser: ", storedUser);

        if (storedUser) {
            fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Update_Bench_Candidates_v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    emailid: storedUser.email,
                    role: storedUser.user_role
                })
            })
                .then(response => response.json())
                .then(data => {
                    const candidates = Object.entries(data).map(([key, value]) => {
                        const id = key.includes(':') ? key.split(': ')[1] : key;
                        return {
                            id,
                            ...value
                        };
                    });
                    // console.log("Transformed candidates: ", candidates);

                    setCandidates(candidates); // Set the candidates state
                    setDataDelete(false);
                })
                .catch(error => console.error('Error fetching candidates:', error));
        }

        return () => {
            setCandidates([]);
        }
    }, [dataDelete]);



    // Fetch recruiters list from the API
    useEffect(() => {
        const fetchRecruiters = async () => {
            try {
                const response = await fetch("https://us-east1-recruiterportal.cloudfunctions.net/Retrieve_Recruiter_Details_v3", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        operation: "retrieve"
                    })
                });

                const data = await response.json();
                if (response.ok) {
                    // Extract unique recruiters list with their ID
                    const recruiters = data.data.map(item => ({ // Extract id
                        recruiter_alias_name: item.recruiter_alias_name || "Unknown"  // Extract assigned_recruiter or default to "Unknown"
                    }));

                    // Update state with the extracted data
                    setBRecruitersList(recruiters);
                    console.log("Recruiters List:", recruiters);
                } else {
                    console.error("Failed to fetch recruiters:", data.message);
                }
            } catch (error) {
                console.error("Error fetching recruiters:", error);
            }
        };

        fetchRecruiters();
    }, []);


    useEffect(() => {
        let filtered = candidates;

        // Search by name
        if (searchBTerm) {
            filtered = filtered.filter(candidate =>
                candidate.first_name.toLowerCase().includes(searchBTerm.toLowerCase()) ||
                candidate.last_name.toLowerCase().includes(searchBTerm.toLowerCase())
            );
        }

        // Filter by visa status
        if (visaBStatusFilter) {
            filtered = filtered.filter(candidate => {
                // If the visa status is "OPT", also check the "OPT Letter Provided" status
                if (visaBStatusFilter === 'OPT') {
                    return candidate.visa_status === 'OPT';
                }
                // Otherwise, just filter by the visa status
                return candidate.visa_status === visaBStatusFilter;
            });
        }


        // Filter by assigned recruiter
        if (recruiterBFilter) {
            filtered = filtered.filter(candidate =>
                candidate.assigned_recruiter === recruiterBFilter
            );
        }

        // Filter by Current status
        if (currentStatusBench) {
            filtered = filtered.filter(candidate =>
                candidate.current_status === currentStatusBench
            );
        }
        // Filter by assigned teams
        // if (teamsBFilter) {
        //     filtered = filtered.filter(candidate => 
        //         // Ensure to handle both string and array formats for assigned_team
        //         // if (Array.isArray(candidate.assigned_team)) {
        //         //     return candidate.assigned_team.includes(teamsBFilter);
        //         //     // console.log(`filter1 ${candidate.assigned_team}`);

        //         // } else {
        //         //     return candidate.assigned_team === teamsBFilter;
        //         //     // console.log(`filter1 ${candidate.assigned_team}`);
        //         // }
        //         candidate.assigned_team===teamsBFilter
        //     );
        // }
        
        if (teamsBFilter) {
            filtered = filtered.filter(candidate => {
                return candidate.assigned_team === teamsBFilter;
            });
        }

        // Filter by location preference
        if (locationFilter) {
            filtered = filtered.filter(candidate =>
                candidate.location_preference === locationFilter
            );
        }

        setFilteredCandidates(filtered);
    }, [searchBTerm, visaBStatusFilter, recruiterBFilter, locationFilter, currentStatusBench, teamsBFilter, candidates]);

    const openModal = (id) => {
        console.log("EDIT");

        navigate(`/benchcandidates/details/edit?id=${id}`);
        setSelectedCandidate(null);

    };

    const closeModal = () => {
        setSelectedCandidate(null);
    };

    // const saveCandidate = (updatedCandidate) => {
    //     // Update candidate in the state
    //     const updatedCandidates = candidates.map(candidate =>
    //         candidate.id === updatedCandidate.id ? updatedCandidate : candidate
    //     );
    //     setCandidates(updatedCandidates);

    //     // Close the modal
    //     closeModal();

    //     // POST request to update candidate in the database
    //     fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             query: `UPDATE foursphere_recruiters.bench_candidates 
    //             SET first_name = '${updatedCandidate.first_name || ''}', 
    //                 last_name = '${updatedCandidate.last_name || ''}', 
    //                 primary_email = '${updatedCandidate.primary_email || ''}', 
    //                 secondary_email = ${updatedCandidate.secondary_email ? `'${updatedCandidate.secondary_email}'` : 'NULL'}, 
    //                 primary_contact = ${updatedCandidate.primary_contact ? `'${updatedCandidate.primary_contact}'` : 'NULL'}, 
    //                 secondary_contact = ${updatedCandidate.secondary_contact ? `'${updatedCandidate.secondary_contact}'` : 'NULL'}, 
    //                 location_preference = '${updatedCandidate.location_preference || ''}', 
    //                 visa_status = '${updatedCandidate.visa_status || ''}', 
    //                 current_status = '${updatedCandidate.current_status || ''}', 
    //                 assigned_recruiter = '${updatedCandidate.assigned_recruiter || ''}', 
    //                 currently_in_project = '${updatedCandidate.currently_in_project || ''}', 
    //                 availability = '${updatedCandidate.availability || ''}', 
    //                 candidate_onedrive_link = '${updatedCandidate.candidate_onedrive_link || ''}', 
    //                 comments = '${updatedCandidate.comments || ''}', 
    //                 cvisa_status = '${updatedCandidate.cvisa_status || ''}', 
    //                 opt_letter_status = '${updatedCandidate.opt_letter_status || ''}', 
    //                 primary_technology = '${updatedCandidate.primary_technology || ''}', 
    //                 secondary_technology = '${updatedCandidate.secondary_technology || ''}',
    //                 assigned_team = '${updatedCandidate.assigned_team || ''}' 
    //             WHERE id = ${updatedCandidate.id};`
    //         })
    //     })
    //         .then(response => {
    //             if (response.ok) {
    //                 console.log('Candidate updated successfully');
    //             } else {
    //                 console.error('Error updating candidate:', response.statusText);
    //             }
    //         })
    //         .catch(error => console.error('Error updating candidate:', error));
    // };

    function goToRegister() {
        navigate("/registerNewCandidates");
    }

    // useEffect(() => {
    //     // Fetch the number of active bench candidates from the API

    //     fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             query: `SELECT COUNT(*) AS count FROM foursphere_recruiters.bench_candidates WHERE current_status = 'Active';`
    //         })
    //     })
    //         .then(response => response.json())
    //         .then(data => setCandidateCount(data[0].count))  // Assuming the API returns an array with count as the first element
    //         .catch(error => console.error('Error fetching candidate count:', error));
    // }, []);

    const sortCandidatesByFirstName = (candidates) => {
        return candidates.length ? candidates.sort((a, b) => {
            // Safely check if first_name exists, sort alphabetically by first_name
            const nameA = a.first_name ? a.first_name.toLowerCase() : '';
            const nameB = b.first_name ? b.first_name.toLowerCase() : '';

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        }) : []
    };

    // Example usage in your component
    const sortedCandidates = sortCandidatesByFirstName(filteredCandidates);

    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 10;



    const totalPages = Math.ceil(sortedCandidates.length / jobsPerPage);

    // Calculate the start and end indices for the current page
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;

    // Get the sortedCandidates for the current page
    const currentJobs = sortedCandidates.slice(indexOfFirstJob, indexOfLastJob);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePageInputChange = (e) => {
        const pageNum = Number(e.target.value);
        if (pageNum >= 1 && pageNum <= totalPages) {
            setCurrentPage(pageNum);
        }
    };

    useEffect(() => {
        handlePageChange(currentPage);
    }, [currentPage]);



    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // Modal state
    const [currentCandidate, setCurrentCandidate] = useState(null); // Candidate data
    const [isAdditionalModalOpen, setIsAdditionalModalOpen] = useState(false); // Subview modal state

    // Function to open the details modal and fetch data
    const handleOpenDetailsModal = (candidateEmail) => {
        {
            // Fetch candidates data on component mount
            fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `SELECT cr.email, cr.from_email, cr.to_email, cr.candidate_full_name, 
                    cr.client_name, cr.rate, cr.submission_status, MAX(cr.submission_date) as 
                    latest_submission_date    FROM foursphere_recruiters.candidate_rateconfirmations cr 
                      JOIN foursphere_recruiters.bench_candidates bc  ON cr.email = bc.primary_email OR 
                      cr.email = bc.secondary_email    WHERE cr.email = '${candidateEmail}'    
                       GROUP BY cr.email, cr.from_email, cr.to_email, cr.candidate_full_name,
                        cr.client_name, cr.rate, cr.submission_status   ORDER BY cr.email ASC, latest_submission_date DESC`
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        setCurrentCandidate(data); // Set candidate data
                        setIsDetailsModalOpen(true); // Open the details modal
                    }
                })
                .catch(error => console.error('Error fetching candidate details:', error));
        };
    }

    const navigateCandidatDetails = (id) => {

        navigate(`/details?id=${id}`);
        // setOPenDetails(true);
    }

    // Function to open the additional modal/subview
    const handleOpenAdditionalModal = () => {
        setIsAdditionalModalOpen(true); // Open additional modal/subview
    };

    // Close functions for both modals
    const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);
    const handleCloseAdditionalModal = () => setIsAdditionalModalOpen(false);

    //delete the hovered bench Candidate
    const deleteCandidate = async (candidate) => {
        if (window.confirm('Are you sure you want to delete')) {
            try {
                const response = await fetch(`https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Update_Bench_Candidates_v3`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {

                            "operation": "delete",
                            
                            "id": `${candidate}`

                        }
                    )
                })
                if (response.ok) {
                    // alert candidate is deleted and list is updated
                    // alert('clicked candidate is Deleted successfully and list is updated');
                    toast.success('Candidate Deleted successfully', {
                                            draggable: "true",
                                            autoClose: 2000,
                                            className: "toast-center",
                                        });
                    setDataDelete(true);
                }
                else {
                    throw new Error('failed to delete candidates');
                }
            }
            catch (error) {
                console.error('Error deleting candidate:' + error.message);
            }
        }
    }


    const ProfileOpen = () => {
        // Open the profile page
        navigate("/userProfile");
        // or navigate("/register", { replace: true }); // replace current URL with new one, without adding it to the history stack
    }

    const handleStatusChange = (event, candidate) => {
        const updatedStatus = event.target.value;
        console.log("candidtefetchdatta", candidate);


        // Update status in the database using candidate id
        fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Update_Bench_Candidates_v3', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({
                emailid: candidate.emailid,
                modify: {
                    id: candidate.id,
                    columns: {
                        onboarded_company: updatedStatus
                    }
                }
            })

        })
            .then(response => response.json())
            .then(data => {
                console.log('Status updated:', data);

                // Update the local state to reflect the change based on id
                setFilteredCandidates(prevCandidates =>
                    prevCandidates.map(c =>
                        c.id === candidate.id ? { ...c, onboarded_company: updatedStatus } : c
                    )
                );
            })
            .catch(error => console.error('Error updating status:', error));
    };

    function candidateAddButton() {
        navigate("/registerNewCandidates");
    }

    return (
        <div>
            <div className="main-dash row-flex">
                <Dashnav />
                {(
                    <section className="w-100">
                        <div className="top-section row-flex">
                            <img src={TopUser} alt="" />
                            <p className="title-left">Bench Candidates Details</p>
                            <button className="profile-button-right" onClick={ProfileOpen}>
                                <img src={Profile} alt="" className='profile' />
                                Your Profile</button>
                        </div>

                        <div className="filters row-flex">
                            <div className="filters-left">
                                <input
                                    type="text"
                                    placeholder="Search by name"
                                    value={searchBTerm}
                                    onChange={(e) => setBSearchTerm(e.target.value)}
                                />

                                <select
                                    value={visaBStatusFilter}
                                    onChange={(e) => setBVisaStatusFilter(e.target.value)}
                                >
                                    <option value="">Filter By Visa</option>
                                    <option value="OPT">OPT</option>
                                    <option value="CPT">CPT</option>
                                    <option value="H1B">H1B</option>
                                    <option value="H4 EAD">H4 EAD</option>
                                    <option value="GC">GC</option>
                                    <option value="GC EAD">GC EAD</option>
                                    <option value="USC">USC</option>
                                    <option value="STEM OPT">STEM OPT</option>
                                </select>

                                <select
                                    type="text"
                                    placeholder="Select Recruiter"
                                    value={recruiterBFilter}
                                    onChange={(e) => setBRecruiterFilter(e.target.value)}
                                >
                                    <option value="">Select Recruiter</option>
                                    {[...new Set(recruitersBList.map(item => item.recruiter_alias_name))]
                                        .map((mailRecruiter, index) => (
                                            <option key={index} value={mailRecruiter}>{mailRecruiter}</option>
                                        ))}
                                </select>


                                <select
                                    type="text"
                                    placeholder="current Status"
                                    value={currentStatusBench}
                                    onChange={(e) => setCurrentStatusBench(e.target.value)}
                                >
                                    <option value="">Filter by Status</option>
                                    {candidates.length && [...new Set(candidates.map(item => item.current_status))]
                                        .map((statusActive, index) => (
                                            <option key={index} value={statusActive}>{statusActive}</option>
                                        ))}
                                </select>
{/* 
                                <select
                                    type="text"
                                    placeholder="Filter by Teams"
                                    value={teamsBFilter}
                                    onChange={(e) => setBTeamsFilter(e.target.value)}
                                >
                                    <option value="">Select Teams</option>

                                    {candidates.length && [...new Set(candidates.map(item => item.assigned_team))]
                                        .map((team, index) => (
                                            <option key={index} value={team}>{team}</option>
                                        ))}
                                </select> */}

                                <select
                                value={teamsBFilter}
                                onChange={(e) => setBTeamsFilter(e.target.value)}
                            >
                                <option value="">Filter By Team</option>
                                <option value="Teams A">Teams A</option>
                                <option value="Teams B">Teams B</option>
                                <option value="Teams C">Teams C</option>
                                <option value="Pending onboarding">Pending onboarding</option>
                            </select>


                            </div>
                            <div className="filters-right">
                                <button><img src={AddIcon} onClick={candidateAddButton} />
                                </button>
                                <div>Candidate: {sortedCandidates.length}</div>
                            </div>
                        </div>

                        <div className='mainContent'>
                            <div className="table-container">
                                <table className="dynamic-table-data">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className='alignCenter name-col'>
                                                    <CandidateNameIcon />
                                                    Candidate Name
                                                </div>
                                            </th>
                                            <th>
                                                <div className='alignCenter email-col'>
                                                    <EmailIcon />
                                                    Email Id
                                                </div>
                                            </th>
                                            <th>
                                                <div className='alignCenter visa-col'>
                                                    <VisaIcon />
                                                    Visa Status
                                                </div>
                                            </th>
                                            <th>
                                                <div className='alignCenter on-col'>
                                                    <CompanyIcon />
                                                    Onboarded Company
                                                </div>
                                            </th>
                                            <th>
                                                <div className='alignCenter'>
                                                    <StatusIcon />
                                                    Status
                                                </div>
                                            </th>
                                            <th>
                                                <div className='alignCenter'>
                                                    <ActionIcon />
                                                    Action
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentJobs.map(candidate => (
                                                <tr key={candidate.id}>
                                                    {
                                                        <td
                                                            className={`status-change-statuschange common-cls${candidate.gender === "Male"
                                                                ? "Male"
                                                                : candidate.gender === "Female"
                                                                    ? "Female"
                                                                    : "Other"
                                                                }`}
                                                            onClick={
                                                                () => navigateCandidatDetails(candidate.id)
                                                            }
                                                        >
                                                            <div className="alignCenter common-cls">

                                                                {candidate.gender === "Male" ? (
                                                                    <NameUserIcon />
                                                                ) : candidate.gender === "Female" ? (
                                                                    <Female />
                                                                ) : (
                                                                    <Others />
                                                                )}

                                                                <span>
                                                                    {candidate.first_name} {candidate.last_name || " "}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    }

                                                    <td>{candidate.primary_email || 'N/A'}</td>

                                                    <td><span className='visa-change'>{candidate.visa_status || 'N/A'}</span></td>

                                                    {/* <td>{candidate.onboarded_company}</td> */}
                                                    <td className='onboarded-data'>
                                                        <select
                                                            className="company-data"
                                                            value={candidate.onboarded_company}
                                                            onChange={(e) => handleStatusChange(e, candidate)}
                                                        >
                                                            <option value="">Select Option</option>
                                                            <option value="4Sphere">4Sphere</option>
                                                            <option value="tb soft">TB Soft</option>
                                                            <option value="ub soft">UB Soft</option>
                                                        </select>
                                                    </td>


                                                    <td
                                                        className={` status-change-statuschange ${candidate.current_status === "Active"
                                                            ? "active"
                                                            : candidate.current_status === "Inactive"
                                                                ? "inactive"
                                                                : "Unknown"
                                                            }`}
                                                    >
                                                        <div className={`status-change ${candidate.current_status}`}>
                                                            <span>
                                                                {candidate.current_status || " "}
                                                            </span>
                                                            {candidate.current_status === "Active" ? (
                                                                <RightTickIcon />
                                                            ) : candidate.current_status === "Inactive" ? (
                                                                <RightTickIcon />
                                                            ) : (
                                                                <i className=" "></i>
                                                            )}
                                                        </div>

                                                    </td>


                                                    {/* <td 
                                                    className={`change-status ${candidate.current_status === "Active"
                                                        ? "Active"
                                                        : candidate.current_status === "Inactive"
                                                            ? "InActive"
                                                            : "Unknown"
                                                        }`}
                                                >
                                                    {candidate.current_status === "Active" ? (
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="5.85199" cy="6.01886" r="5.7558" fill="#F1F1F1"/>
                                                        <path d="M8.61038 3.92041L4.71323 8.11735L2.76465 6.01888" stroke="#1BD084" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    ) : candidate.current_status === "Inactive" ? (
                                                        <i className=""></i>
                                                    ) : (
                                                        <i className=" "></i>
                                                    )}
                                                    <span>{candidate.current_status || " "}</span>
                                                </td> */}

                                                    <td>
                                                        <button onClick={() => openModal(candidate.id)} className="button-icon">
                                                            {/* icon for Edit */}
                                                            <EditIconButton />

                                                        </button>
                                                        <button
                                                            onClick={() => deleteCandidate(candidate.id)}
                                                            className="button-icon"
                                                        >
                                                            {/* icon for delete */}
                                                            <DeleteIcon />

                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>


                                    {/* Direct Page Input */}
                                    {/* <div style={{ marginTop: '10px' }}>
                                    <label>
                                    Go to page:{' '}
                                    <input
                                        type="number"
                                        min="1"
                                        max={totalPages}
                                        value={currentPage}
                                        onChange={handlePageInputChange}
                                    />
                                    </label>
                                </div> */}

                                </table>
                                {/* Pagination Controls */}

                                <div className='row-flex navigation' style={{ marginTop: '10px' }}>
                                    <button className='left nav' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                                            <path d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z" fill="#341FA8" />
                                        </svg>

                                    </button>
                                    <span style={{ margin: '0 10px' }} className='text'>
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button className='nav' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10.3264" cy="10.5759" r="9.92407" fill="#D6D1F3" />
                                            <path d="M12.804 10.9292C12.9992 10.734 12.9992 10.4174 12.804 10.2221L9.62198 7.04015C9.42672 6.84489 9.11014 6.84489 8.91488 7.04015C8.71961 7.23541 8.71961 7.55199 8.91488 7.74726L11.7433 10.5757L8.91488 13.4041C8.71961 13.5994 8.71961 13.916 8.91488 14.1112C9.11014 14.3065 9.42672 14.3065 9.62198 14.1112L12.804 10.9292ZM12.1279 11.0757L12.4504 11.0757L12.4504 10.0757L12.1279 10.0757L12.1279 11.0757Z" fill="#341FA8" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>



                    </section>
                )}

                {openDetails && (<BenchDetails openDetails={openDetails} setOPenDetails={setOPenDetails} />)}
            </div>
            {isDetailsModalOpen && currentCandidate && (
                <CandidateDetailsModal
                    candidate={currentCandidate}
                    onClose={handleCloseDetailsModal}
                />
            )}

            {selectedCandidate && (
                <CandidateDetailsEdit
                    candidate={selectedCandidate}
                // onSave={saveCandidate}
                />
            )}


        </div>
    );
};

export default BenchCandidates;
