import React, { useState, useEffect } from 'react';
import "./editCandidate.css";

const FilePreview = ({ base64File, fileType, setBase64File, setFileType }) => {
  const [blobUrl, setBlobUrl] = useState(null);
  const [isClose,setIsClose] = useState("none")
// Update blobUrl when base64File or fileType changes
useEffect(() => {
    if (base64File && fileType === fileType) {
      const byteCharacters = atob(base64File);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      const blob = new Blob(byteArrays, { type: fileType });
      const url = URL.createObjectURL(blob);
      setBlobUrl(url);
      setIsClose("flex");
      // Cleanup: Revoke the Blob URL when the component unmounts or the file changes
      return () => URL.revokeObjectURL(url);
    }
  }, [base64File, fileType]);

  const handleCloseIframe = () => {
    setBase64File(null)
    setFileType(null)
    setIsClose('none')
    setBlobUrl(null)
  }

  return (
    <div className='iframeModal' style={{"display": isClose}}>
      {/* <h3>File Preview</h3>  */}
      {base64File && blobUrl ? (
        fileType === fileType ? (
          <iframe
            src={blobUrl}
            title="PDF Preview"
            type="fileType"
            className='iFrame-properties'
          ></iframe>
        ) : (
          <p>Cannot preview this file type in an iframe.</p>
        )
      ) : (
        <p>No file to Preview</p>
      )}
      <button className='iframeClose-btn' onClick={() => handleCloseIframe()}>Close</button>
    </div>
  );
};

export default FilePreview;
