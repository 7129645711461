import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../../authContext';
import Dashnav from '../../components/dashnav';
import "./style.css";

import ProfileDef from "./profileIcon.svg";
import UserDash from "./user-dash.png";
import BenchIcon from "./benchdashicon.svg";
import PageIcon from "./DashIcon.svg";
import RegisterIcon from "./RegisterIcon.svg";
import CandidatesIcon from "../myAssignedCandidates/DashIcon.svg"
import MySubmission from '../mysubmission/mySubmission';
import { useGlobalContext } from '../../globalContext';

const Dashboard = () => {
    const { isLoggedIn, login, user } = useAuth();
    const { setSideBarLeft} = useGlobalContext();
    const [candidateCount, setCandidateCount] = useState(0);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [assignedCandidates, setAssignedCandidates] = useState(0);
    const [mySubmissions, setMySubmissions] = useState([]);
    const [emailAttempted, setEmailAttempted] = useState(false); // Track if email has been attempted
    const navigate = useNavigate();
    

    useEffect(() => {
        if (user) {
            console.log(user);
        }
    }, [login, user]);

    const ProfileOpen = () => {
        navigate("/userProfile");
    };

    useEffect(() => {
        if (user && user.user_role === "admin") {
            // Fetch the number of active bench candidates from the API
            fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Details_Frontend_Dashboard_v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    assigned_recruiter_email: user.email
                })
            })
                .then(response => response.json())
                .then(data => setCandidateCount(data.active_candidates_overall))
                .catch(error => console.error('Error fetching candidate count:', error));
        }
        else if (user?.user_role === "recruiter") {
            console.log("You are a Recruiter");
        }
    }, [user]);



    useEffect(() => {
        // Fetch the number of assigned candidates for the current user
        if (user && user.user_role) {
            fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Details_Frontend_Dashboard_v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    role: user.user_role,
                    assigned_recruiter_email: user.email
                })
            })
                .then(response => response.json())
                .then(data => setAssignedCandidates(data.assigned_candidates_recruiter))
                .catch(error => console.error('Error fetching assigned candidate count:', error));
        }
    }, [user, user?.email]);

    const goToCanditates = () => {
        navigate("/benchcandidates");
    };

    const goToRegisterCanditates = () => {
        navigate("/registerNewCandidates");
    };

    const goToRateCanditates = () => {
        navigate("/rateCandidates");
    };

    const goToMyAssignedCandidates = () => {
        navigate("/myAssignedCandidates");
    };

    const username = user ? user.email.split('@')[0] : "Your Name";

    useEffect(() => {
        if (user && user.user_role === "admin") {
            fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Details_Frontend_Dashboard_v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    assigned_recruiter_email: user.email
                })
            })
                .then(response => response.json())
                .then(data => setTotalCandidates(data.rate_confirmations_overall))

                .catch(error => {
                    console.error('Error fetching data:', error);
                    setTotalCandidates(0);
                });
        }
        else if (user?.user_role === "recruiter") {
            console.log("Recruiter Logged In");
        }
    }, [user]);

    useEffect(() => {
        if (user && user.user_role === "recruiter") {
            // Check if user and user.email are available
            fetch('https://us-east1-recruiterportal.cloudfunctions.net/Fetch_Details_Frontend_Dashboard_v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    role: user.user_role,
                    assigned_recruiter_email: user.email
                })
            })
                .then(response => response.json())
                .then(data => setMySubmissions(data.rate_confirmations_recruiter))

                .catch(error => {
                    console.error('Error fetching data:', error);
                    setMySubmissions(0);
                });
        }
        else if (user?.user_role === 'admin') {
            console.log("Admin user logged in");
        }
    }, [user]);

    

    return (
        <div>
            <div className="main-dash row-flex">
                <Dashnav />
                <div className="content col-flex">
                    <div className="top-bar row-flex">
                        <div className="col-flex">
                            <div className="page-intro row-flex">
                                <img src={PageIcon} alt="page-icon" />
                                <h3 className="page-head blue">Dashboard</h3>
                            </div>
                        </div>
                        <div className="row-flex nav-sidebar-button">
                            <div className="profile-button row-flex" onClick={ProfileOpen}>
                                <img src={ProfileDef} alt="" className="thumb" />
                                <div className="user-col row-flex">
                                    <h3 className="position">Your Profile</h3>
                                </div>
                            </div>
                            <div className=" sidenav-icon row-flex" onClick={setSideBarLeft}>
                                <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M17 8L21 12L17 16M3 12H13M3 6H13M3 18H13"></path>
                                </svg>
                            </div>
                        </div>  
                    </div>
                    <div className="innerContent col-flex">
                        <div className="user-dash row-flex">
                            <div className="user-col col-flex">
                                <h2>Stay Ahead in Recruitment</h2>
                                <h3>Welcome {username}</h3>
                            </div>
                            <div className="user-col col-flex">
                                <img src={UserDash} alt="" className="userdash-thumb" />
                            </div>
                        </div>
                        <div className="bench-row row-flex">
                            {user?.user_role === 'admin' &&
                                <div className="dash-cards col-flex" onClick={goToCanditates}>
                                    <div className="info-row row-flex justify-cls">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={BenchIcon} alt="BenchIcon" /></div>
                                            <h5>No. Of Active Candidates</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{candidateCount}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {user?.user_role === 'recruiter' &&
                                <div className="dash-cards col-flex justify-cls" onClick={goToMyAssignedCandidates}>
                                    <div className="info-row row-flex">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={BenchIcon} alt="BenchIcon" /></div>
                                            <h5>No. Of Rate Submissions</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{mySubmissions}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {user?.user_role === 'recruiter' &&
                                <div className="dash-cards col-flex" onClick={goToMyAssignedCandidates}>
                                    <div className="info-row row-flex">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={CandidatesIcon} alt="BenchIcon" /></div>
                                            <h5>No. Of Assigned Candidates</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{assignedCandidates}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {user?.user_role === 'admin' &&
                                <div className="dash-cards col-flex" onClick={goToRateCanditates}>
                                    <div className="info-row row-flex">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={BenchIcon} alt="BenchIcon" /></div>
                                            <h5>Total Number Of Rate Confirmation</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{totalCandidates}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* <div className="dash-cards col-flex" onClick={goToRegisterCanditates}>
                                <div className="info-row row-flex">
                                    <div className="info-col col-flex">
                                        <div className="icon"><img src={RegisterIcon} alt="BenchIcon" /></div>
                                        <h5>Register New Candidates</h5>
                                    </div>
                                    <div className="info-col">
                                        <span></span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
